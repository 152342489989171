import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import moment from "moment";

function Banner() {
  const [currentTime, setCurrentTime] = useState(moment());
  const timeBetween = moment.duration(currentTime.diff(moment("2024-09-08")));

  return (
    <div style={{ transform: "none" }}>
      <div className='flex fixed sm:flex-row flex-col z-[999] mt-0 items-center sm:justify-center justify-between sm:px-3 px-1 sm:h-10 h-18 py-2 w-full bg-gradient-to-r from-purple-500 to-slate-800 text-white'>
        <div className='flex items-center sm:pb-0 pb-1'>
          <motion.div
            transition={{ repeat: Infinity, duration: 17, ease: "linear" }}
            animate={{ rotate: 360 }}
            className='mr-1'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={20}
              height={20}
              className=''
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              strokeWidth={2}
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z'
              />
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
              />
            </svg>
          </motion.div>
          <span className='text-xs leading-tight'>
            <strong className='font-semibold lg:inline-block block'>
              The Longest Running Miner
            </strong>
          </span>
        </div>

        <button className='sm:ml-3 text-xs font-semibold bg-white hover:bg-red-50 hover:shadow-xl text-purple-800 rounded-lg py-2 px-3 leading-none hover:scale-105 transform ease-in-out duration-150 transition-all'>
          {timeBetween.months()} months {timeBetween.days()} Days{" "}
          {timeBetween.hours()} Hours {timeBetween.minutes()} Min
        </button>
      </div>
    </div>
  );
}

export default Banner;
